import axios from 'axios';
import { getSessionToken, getStoreInfo, navigateExit } from '@tiendanube/nexo';
import nexo from '../nexoClient';

const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}`,
});

apiInstance.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const store = await getStoreInfo(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers = {
    ...request.headers,
    Authorization: bearerToken,
    'Store-Country': `${store.country}`,
  } as any;
  return request;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default apiInstance;
