import React from 'react';
import { ThemeProvider } from '@nimbus-ds/styles';
import { Box } from '@nimbus-ds/components';
import NavButtons from '../NavButtons';

const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // The admin currently doesn't support dark mode. When it does, replace `base` value with `currentTheme` to provide dark mode support.
  return (
    <ThemeProvider theme="base">
      <Box paddingBottom="18">
        <Box display="block" width="100%" marginBottom="2">
          <Box display={{ xs: 'none', lg: 'block' }}>
            <a
              href="https://www.nuvemshop.com.br/loja-aplicativos-nuvem/busca?q=huapps"
              title="Nossos apps"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'block', width: '100%' }}
            >
              <img
                src="https://d2r9epyceweg5n.cloudfront.net/stores/002/166/951/rte/banner-regua-desk.png"
                alt="Nossos apps"
                style={{ display: 'block', width: '100%', height: 'auto' }}
              />
            </a>
          </Box>
          <Box display={{ xs: 'block', lg: 'none' }}>
            <a
              href="https://www.nuvemshop.com.br/loja-aplicativos-nuvem/busca?q=huapps"
              title="Nossos apps"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'block', width: '100%' }}
            >
              <img
                src="https://d2r9epyceweg5n.cloudfront.net/stores/002/166/951/rte/banner-regua-mob.png"
                alt="Nossos apps"
                style={{ display: 'block', width: '100%', height: 'auto' }}
              />
            </a>
          </Box>
        </Box>
        {children}
        <NavButtons />
      </Box>
    </ThemeProvider>
  );
};

export default BaseLayout;
