import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout } from '@nimbus-ds/patterns';
import {
  Box,
  useToast,
  Spinner,
  Alert,
  Button,
  Text,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import apiMDInstance from '../../utils/apiMiddlewareUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../../lib/i18n/i18n';

const BillingPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [lang, setLang] = useState<any>('pt');
  const [refreshButton, setRefreshButton] = useState(false);

  const applyError = () => {
    setLoading(false);
    setErrorMessage(true);

    addToast({
      type: 'danger',
      text: t('BillingPage.Toasts.StartCheckout.ErrorRedirect'),
      id: new Date().toISOString(),
      position: 4,
    });
  };

  const prepareURL = async () => {
    setLoading(true);
    setErrorMessage(false);

    const searchParams = new URLSearchParams(location.search);
    const coupon = searchParams.get('coupon'); // Pega o valor do cupom

    const { data, status } = await apiMDInstance(
      `/billing/url-session${coupon ? `?coupon=${coupon}` : ''}`,
    );

    if (status === 200 && data) {
      if (
        window.parent &&
        typeof data.url === 'string' &&
        data.url.trim() !== ''
      ) {
        window.parent.location.href = data.url;
        return;
      }
    }
    applyError();
    console.error('Não foi possível redirecionar para o site externo.');
  };

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('General.back')}` });
    setLang(i18n.language);
    prepareURL();

    setTimeout(() => {
      setRefreshButton(true);
    }, 2500);
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={lang === 'pt' ? 'Minha assinatura' : 'Mi firma'}
          alignItems="center"
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="20"
                gap="4"
              >
                {loading && (
                  <Box display="grid" gap="4">
                    <Box display="flex" justifyContent="center">
                      <Spinner size="large" />
                    </Box>
                    {refreshButton && (
                      <Box
                        display="grid"
                        gap="4"
                        justifyContent="center"
                        alignItems="center"
                        maxWidth="400px"
                      >
                        <Text
                          fontWeight="bold"
                          fontSize="highlight"
                          textAlign="center"
                        >
                          ⚠️
                        </Text>
                        <Text fontWeight="bold" textAlign="center">
                          {lang === 'pt'
                            ? 'Atenção: Se você está vendo esta mensagem, verifique se o seu navegador está bloqueando a abertura de novas janelas.'
                            : 'Atención: si ve este mensaje, verifique si su navegador está bloqueando la apertura de nuevas ventanas.'}
                        </Text>
                        <Box display="flex" justifyContent="center">
                          <Button onClick={prepareURL}>
                            {lang === 'pt'
                              ? 'Tentar novamente'
                              : 'Intentar otra vez'}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}

                {!loading && errorMessage && (
                  <Alert
                    title={
                      lang === 'pt'
                        ? 'Erro ao acessar cobranças'
                        : 'Error al acceder a los cargos'
                    }
                  >
                    {lang === 'pt'
                      ? 'Ocorreu um erro inesperado ao recuperar os dados da assinatura do app. Se o problema persistir, entre em contato com o suporte.'
                      : 'Se produjo un error inesperado al recuperar los datos de suscripción de la aplicación. Si el problema persiste, comuníquese con el soporte.'}
                    <Box display="flex" flexWrap="wrap" gap="2">
                      <Button onClick={prepareURL}>
                        {lang === 'pt'
                          ? 'Tentar novamente'
                          : 'Intentar otra vez'}
                      </Button>
                    </Box>
                  </Alert>
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default BillingPage;
